import React from 'react';

function DebugGrid() {
  return null;
  // return (
  //   <div
  //     className="ft-grid"
  //     style={{
  //       display: 'none',
  //       position: 'fixed',
  //       top: 0,
  //       left: 0,
  //       width: '100%',
  //       pointerEvents: 'none',
  //     }}
  //   >
  //     <div
  //       className="ft-grid__full ft-flex ft-flex--row"
  //       style={{
  //         width: 'var(--ft-grid--width)',
  //         marginLeft: 0,
  //         paddingLeft: 0,
  //         paddingRight: 0,
  //       }}
  //     >
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //       <div
  //         style={{
  //           width: 'var(--ft-grid--column-width)',
  //           paddingLeft: 'calc(var(--ft-grid--column-gap) / 2)',
  //           paddingRight: 'calc(var(--ft-grid--column-gap) / 2)',
  //           backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //         }}
  //       >
  //         <div
  //           style={{
  //             height: '100vh',
  //             backgroundColor: 'hsla(0, 0%, 0%, .1)',
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default DebugGrid;

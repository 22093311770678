import React from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';

import { appendJs } from '../utils/helper';

const propTypes = {
  content: PropTypes.string.isRequired,
};

const defaultProps = {

};

function WordPressContent({ content }) {
  return (
    <>
      {
        Parser(content, {
          replace: ({ type, name, attribs }) => {
            // Execute content scripts
            if (type === 'script' && name === 'script' && attribs && attribs.src) {
              appendJs(attribs.src);
            }
          },
        })
      }
    </>
  );
}

WordPressContent.propTypes = propTypes;
WordPressContent.defaultProps = defaultProps;

export default WordPressContent;
